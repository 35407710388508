import { CaseReasonsLookupField } from "@/features/caseReasons/components/CaseReasonsLookupField";
import { updateEntity } from "@/features/entity/api";
import { useTableStore } from "@/features/entity/stores/useTableStore";
import { OriginCategoriesLookup } from "@/features/originCategories/components/OriginCategoriesLookup";
import { type PathKeys } from "@/types";
import { Button, Flex, Modal, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconSquareRoundedMinus } from "@tabler/icons-react";

import {
  type MRT_Row,
  type MRT_RowData,
  type MRT_TableInstance,
} from "mantine-react-table";
import { useState, type MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

interface CaseCloseButtonListProps {
  table?: MutableRefObject<MRT_TableInstance<MRT_RowData> | null>;
  entityPath?: string;
  queryKey?: string;
  setLoading?: (value: boolean) => void;
}

interface BasicEntity extends MRT_RowData {
  id?: string;
}

export default function CaseCloseButtonList({
  table,
  entityPath,
  queryKey,
  setLoading,
}: CaseCloseButtonListProps) {
  const { t } = useTranslation("features");

  const form = useForm({
    initialValues: {
      caseReasonId: "",
    },
  });

  const [originCategory, setOriginCategory] = useState<string | null>(null);

  const [
    confirmationOpened,
    { open: openConfirmation, close: closeConfirmation },
  ] = useDisclosure(false);

  const queryCache = useQueryClient();

  const viewValue = useTableStore((state) => state.viewValue);

  if (viewValue == "Inactive") {
    return <></>;
  }

  const closeModal = () => {
    setOriginCategory(null);
    closeConfirmation();
  };

  const handleCloseCases = async (rows: MRT_Row<MRT_RowData>[]) => {
    try {
      const updatePromises = rows.map((row) => {
        const data = row?.original as BasicEntity | null;
        return updateEntity({
          resourcePath: `/api/${entityPath ?? ""}/${data?.id}` as PathKeys,
          resourceId: data?.id ?? "",
          params: {
            status: "Closed",
            caseReasonId: form.getValues().caseReasonId,
          },
        });
      });

      await Promise.all(updatePromises);

      return true;
    } catch (error) {
      notifications.show({
        color: "red",
        title: t("errors.defaultTitle"),
        message: `${(error as Error).message}`,
      });

      return false;
    }
  };

  const handleConfirm = async () => {
    if (
      form.getValues().caseReasonId === "" ||
      form.getValues().caseReasonId === null
    ) {
      notifications.show({
        color: "red",
        title: t("errors.defaultTitle"),
        message: t("cases.caseReasonRequired"),
      });
      return;
    }
    if (table?.current) {
      const selectedRowModel = table.current.getSelectedRowModel();
      table.current.resetRowSelection();
      if (selectedRowModel.rows?.length > 0) {
        setLoading?.(true);
        closeModal();

        const success = await handleCloseCases(selectedRowModel.rows);
        await queryCache.invalidateQueries(`${queryKey!}_list`);

        if (success) {
          notifications.show({
            color: "green",
            title: t("notifications.successTitle"),
            message: t("notifications.updateSuccessMessage"),
          });
        }
        setLoading?.(false);
      }
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          if (table?.current?.getSelectedRowModel().rows?.length === 0) {
            notifications.show({
              color: "red",
              title: t("No Cases Selected"),
              message: t("Please select at least one row to proceed."),
            });
          } else {
            openConfirmation();
          }
        }}
        variant="subtle"
        color="red"
        type="button"
        size="compact-md"
        leftSection={<IconSquareRoundedMinus size={18} />}
      >
        <Text size="sm" fw={600}>
          {t("cases.closeCasesButton")}
        </Text>
      </Button>

      <Modal
        opened={confirmationOpened}
        onClose={closeModal}
        centered
        title={
          <Text fw={600} size="lg">
            {t("cases.closeCasesTitle")}
          </Text>
        }
      >
        <Stack m={"md"} gap={"xs"}>
          <OriginCategoriesLookup
            required
            initial={null}
            label={t("cases.originCategories")}
            onChange={(value: string) => {
              setOriginCategory(value);
            }}
            {...{ labelProps: { style: { flex: 2 } } }}
          />
          <CaseReasonsLookupField
            disabled={originCategory === null}
            originCategory={originCategory ?? ""}
            label={t("cases.caseReasons")}
            {...form.getInputProps("caseReasonId")}
            {...{ labelProps: { style: { flex: 2 } } }}
          />
        </Stack>
        <Text m="md" fw={600} size="sm">
          {t("cases.casesCloseConfirmation")}
        </Text>
        <Flex gap="xs" justify={"flex-end"}>
          <Button onClick={closeModal} variant="light" color="black" mt={"xs"}>
            {t("cases.closeCasesCancel")}
          </Button>
          <Button
            color="red"
            mt={"xs"}
            onClick={() => {
              void handleConfirm();
            }}
          >
            {t("cases.closeCasesConfirm")}
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
