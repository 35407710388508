import { Combobox, TextInput, useCombobox } from "@mantine/core";
import { useDebounceCallback } from "usehooks-ts";
import { useState } from "react";

const intervals = ["15", "30", "45", "60"];

interface DurationComboboxProps {
  startDate: Date | null;
  endDate: Date | null;
  setEndDate: (minutes: string) => void;
  isModal?: boolean;
}

function getDurationInMinutes(startDate: Date, endDate: Date) {
  const duration =
    new Date(endDate ?? "").getTime() - new Date(startDate ?? "").getTime();
  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

  return (hours * 60 + minutes).toString();
}

export function GetDurationString(startDate: Date, endDate: Date) {
  const duration =
    new Date(endDate ?? "").getTime() - new Date(startDate ?? "").getTime();
  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const durationString = `${hours} h ${minutes} min`;
  return durationString;
}

export function DurationCombobox({
  startDate,
  endDate,
  setEndDate,
  isModal,
}: DurationComboboxProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const onSubmit = (value: string) => {
    const newEndDate = new Date(
      (startDate?.getTime() ?? 0) + parseInt(value) * 60000,
    );
    setEndDate(GetDurationString(startDate!, newEndDate));
  };

  const debouncedOnSubmit = useDebounceCallback(onSubmit, 500);
  const [data, setData] = useState(intervals);
  const [search, setSearch] = useState(
    getDurationInMinutes(startDate!, endDate!),
  );
  const options = data.map((item) => (
    <Combobox.Option value={item} key={item}>
      {item}
    </Combobox.Option>
  ));
  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        if (val === "$create") {
          setData((current) => [...current, search]);
          debouncedOnSubmit(search);
        } else {
          setSearch(val);
          debouncedOnSubmit(val);
        }

        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <TextInput
          size="xs"
          flex={1}
          label={"Duration"}
          rightSection={<Combobox.Chevron />}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          rightSectionPointerEvents="none"
          {...{
            labelProps: {
              style: {
                flex: isModal ? "1.75" : "3.2",
                fontSize: "0.7rem",
                fontWeight: "bold",
              },
            },
          }}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {search.trim().length > 0 && (
            <Combobox.Option value="$create">{search}</Combobox.Option>
          )}
          {options}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
