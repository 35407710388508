import { useState } from "react";
import { Popover, Button, Stack, Modal, Flex, Box } from "@mantine/core";
import { IconChevronDown, IconStar, IconStars } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { type Schemas } from "@/types";
import { PhoneCallCreate } from "@/features/phoneCalls/routes/PhoneCallCreate";
import { useDisclosure } from "@mantine/hooks";
import { AppointmentCreate } from "@/features/appointments/routes/AppointmentCreate";
import { useTranslation } from "react-i18next";

interface OCLeadTabProps {
  value: string;
  currentValue: string;
  label: string;
  lead?: Schemas["LeadRetrieveDto"];
  priority?: boolean;
}

export function OCLeadTab({
  value,
  currentValue,
  label,
  lead,
  priority = false,
}: OCLeadTabProps) {
  const isActive = value == currentValue;
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation("features");
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedActivity, setSelectedActivity] = useState<
    "Appointment" | "PhoneCall" | "Email" | "Sms" | undefined
  >(undefined);

  const handleRemove = () => {
    console.log("Remove");
  };

  const handleMouseEnter = () => {
    if (isActive) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Popover
        opened={isHovered}
        position="bottom"
        withArrow
        shadow="md"
        width={200}
      >
        <Popover.Target>
          <div style={{ display: "inline-block" }} onClick={handleMouseEnter}>
            <Button
              flex={1}
              value={value}
              variant={isActive ? "filled" : priority ? "outline" : "outline"}
              leftSection={
                isActive ? (
                  <IconChevronDown style={{ width: 16, height: 16 }} />
                ) : priority ? (
                  <IconStars style={{ width: 16, height: 16 }} />
                ) : (
                  <IconStar style={{ width: 16, height: 16 }} />
                )
              }
              color={
                isActive
                  ? priority
                    ? "green"
                    : "blue"
                  : priority
                    ? "green"
                    : "blue"
              }
              onClick={() => {
                if (isActive) {
                  handleRemove();
                } else {
                  navigate(`/app/leads/${value}?redirectTo=/app/omnichannel`);
                }
              }}
            >
              {label === "" ? t("omnichannel.fullNameIsEmpty") : label}
            </Button>
          </div>
        </Popover.Target>
        <Popover.Dropdown onMouseLeave={handleMouseLeave}>
          <Stack>
            <Button
              m={5}
              variant="subtle"
              fullWidth
              onClick={() => {
                const phone = "tel:" + lead?.mobile;
                window.location.href = phone;
              }}
            >
              <Flex direction="column" m={5}>
                <Box>{lead?.mobile}</Box>
              </Flex>
            </Button>
            <Button
              variant="subtle"
              fullWidth
              onClick={() => {
                setSelectedActivity("PhoneCall");
                open();
              }}
            >
              {t("omnichannel.createCall")}
            </Button>
            <Button
              variant="subtle"
              fullWidth
              onClick={() => {
                setSelectedActivity("Appointment");
                open();
              }}
            >
              {t("omnichannel.createAppointment")}
            </Button>
          </Stack>
        </Popover.Dropdown>
      </Popover>
      {opened && (
        <Modal
          centered
          withCloseButton={false}
          size="60%"
          opened={opened}
          onClose={close}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          {selectedActivity === "Appointment" ? (
            <AppointmentCreate
              closeModal={() => {
                close();
              }}
              isModal={true}
              leadId={lead?.id}
              businessUnitId={lead?.businessUnitId}
              startDate={new Date().toISOString()}
            />
          ) : selectedActivity === "PhoneCall" ? (
            <PhoneCallCreate
              closeModal={() => {
                close();
              }}
              isModal={true}
              leadId={lead?.id}
              businessUnitId={lead?.businessUnitId}
              startDate={new Date().toISOString()}
            />
          ) : null}
        </Modal>
      )}
    </>
  );
}
