import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-react-table/styles.css";
import "@mantine/charts/styles.css";
import "@/styles/globals.css";
import * as Sentry from "@sentry/react";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { App } from "@/App";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // Do nothing
} else {
  Sentry.init({
    dsn: "https://4b765c9e2cf30f0a42060f5c93d866a2@o4507372648792064.ingest.de.sentry.io/4507498247749712",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
      Sentry.feedbackIntegration({
        colorScheme: "light",
        showBranging: false,
        autoInject: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "https://unitlogic.nl",
      "https://unitlogic.nl/*",
      "https://unitlogic.nl/**",
      "https://accept.unitlogic.nl",
      "https://accept.unitlogic.nl/*",
      "https://accept.unitlogic.nl/**",
      "https://white-grass-09646b903.4.azurestaticapps.net",
      "https://white-grass-09646b903.4.azurestaticapps.net/*",
      "https://white-grass-09646b903.4.azurestaticapps.net/**",
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
