import { create } from "zustand";

interface TableStore {
  viewName?: string;
  setViewName: (viewName: string) => void;
  viewValue?: string;
  setViewValue: (viewName: string) => void;
}

export const useTableStore = create<TableStore>((set) => ({
  viewName: undefined,
  setViewName: (viewName: string) => set(() => ({ viewName: viewName })),
  viewValue: undefined,
  setViewValue: (viewValue: string) => set(() => ({ viewValue: viewValue })),
}));
