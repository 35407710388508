import { Button } from "@mantine/core";
import {
  type BrowserClient,
  type feedbackIntegration,
  getClient,
} from "@sentry/react";
import { IconSpeakerphone } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type FeedbackIntegration = ReturnType<typeof feedbackIntegration>;

export function FeedbackButton() {
  const client = getClient<BrowserClient>();
  const { t } = useTranslation("common");
  const feedback =
    client?.getIntegrationByName<FeedbackIntegration>("Feedback");

  // Don't render custom feedback button if Feedback integration isn't installed
  if (!feedback) {
    return null;
  }

  return (
    <Button
      variant="light"
      color="red"
      size="xs"
      justify="space-between"
      radius="xl"
      rightSection={<IconSpeakerphone size={16} />}
      onClick={async () => {
        const form = await feedback.createForm();
        form.appendToDom();
        form.open();
      }}
    >
      {t("navbar.reportBug")}
    </Button>
  );
}
