import { type PathKeys } from "@/types";
import { useQuery, type QueryKey, useInfiniteQuery } from "react-query";
import {
  type QueryParams,
  getEntity,
  getEntityList,
  getEntitiesByIds,
} from "../api";

interface EntityListQueryProps {
  resourcePath: PathKeys;
  queryKey: QueryKey;
  enabled?: boolean;
  params?: QueryParams;
  refetchInterval?: number;
}

export function useEntityListQuery<TResponse>({
  resourcePath,
  queryKey,
  enabled = true,
  refetchInterval = undefined,
  params = {},
}: EntityListQueryProps) {
  return useQuery({
    queryKey: [
      `${queryKey as string}_list`,
      params?.pageNumber,
      params?.pageSize,
      params?.searchBy,
      params?.filter,
      params?.orderBy,
      params?.desc,
    ],
    queryFn: () => getEntityList<TResponse>({ resourcePath, params }),
    keepPreviousData: true,
    refetchInterval: refetchInterval,
    enabled: enabled,
  });
}
interface EntitiesByIdsQueryProps {
  resourcePath: PathKeys;
  resourceIds: string[];
  queryKey: QueryKey;
  params?: QueryParams;
}

export function useEntitiesByIdsQuery<TResponse>({
  resourcePath,
  resourceIds,
  queryKey,
  params = {},
}: EntitiesByIdsQueryProps) {
  return useQuery(
    `${queryKey as string}_${resourceIds.join(",")}`,
    () => getEntitiesByIds<TResponse[]>({ resourcePath, resourceIds, params }),
    { enabled: !!resourceIds },
  );
}
interface BasicResponse {
  hasNext?: boolean;
  currentPage?: number;
}

interface InfiniteEntityListQuery {
  resourcePath: PathKeys;
  queryKey: QueryKey;
  params?: QueryParams;
  enabled?: boolean;
}

export function useInfiniteEntityListQuery<TResponse extends BasicResponse>({
  resourcePath,
  queryKey,
  params,
  enabled,
}: InfiniteEntityListQuery) {
  return useInfiniteQuery({
    queryKey: [
      `${queryKey as string}_list`,
      params?.pageNumber ?? 0,
      params?.searchBy,
    ],
    enabled: enabled,
    queryFn: ({ pageParam = 0 }) => {
      return getEntityList<TResponse>({
        resourcePath,
        params: {
          pageSize: 20,
          ...params,
          pageNumber: pageParam as number,
        },
      });
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNext) {
        return lastPage.currentPage! + 1;
      }

      return undefined;
    },
  });
}

interface EntityQueryProps {
  resourcePath: PathKeys;
  resourceId: string;
  queryKey: QueryKey;
}

export function useEntityQuery<TResponse>({
  resourcePath,
  resourceId,
  queryKey,
}: EntityQueryProps) {
  return useQuery(
    `${queryKey as string}_${resourceId}`,
    () => getEntity<TResponse>({ resourcePath, resourceId }),
    { enabled: !!resourceId },
  );
}
