import {
  AppShell,
  Box,
  Button,
  Center,
  Combobox,
  Flex,
  Loader,
  ScrollArea,
  Stack,
  useCombobox,
} from "@mantine/core";
import UseNavBar from "@/hooks/useNavBar";

import { renderNavBar } from "./utils";
import { useUserContext } from "../Contexts/User/useUserContext";
import { useLayoutVisibility } from "../Contexts/LayoutVisibility/LayoutVisibilityContext";
import { FeedbackButton } from "@/components/Layout/Navbar/FeedbackButton";
import { IconChevronUp } from "@tabler/icons-react";

interface NavbarProps {
  onClickMobile: VoidFunction;
}

export function Navbar({ onClickMobile }: NavbarProps) {
  const { isLoading, menuLinks, options, currentAppId, updateUser } =
    UseNavBar();
  const { setActiveNavbar } = useLayoutVisibility();
  const { roles } = useUserContext();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.selectActiveOption();
    },
  });

  if (isLoading) {
    return (
      <AppShell.Navbar>
        <Center w={"100%"} h={"100%"}>
          <Loader />
        </Center>
      </AppShell.Navbar>
    );
  }

  const AppOptions = options.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      active={item.value == currentAppId}
    >
      {item.label}
    </Combobox.Option>
  ));

  return (
    <AppShell.Navbar>
      <Flex direction="column" justify="space-between" h={"100%"}>
        <ScrollArea w={"100%"} type="always" scrollbars="y">
          <Stack justify="flex-end" flex={1}>
            <Flex justify="center" align="center" direction="column" w={"90%"}>
              {renderNavBar(menuLinks, roles, onClickMobile)}
            </Flex>
            <Box flex={1} />
          </Stack>
        </ScrollArea>
        <Stack my={16} mx={8} gap={8}>
          <FeedbackButton />

          <Combobox
            store={combobox}
            width={"180"}
            position="top-start"
            withArrow
            onOptionSubmit={(value) => {
              if (value) {
                updateUser(value);
                setActiveNavbar(
                  options.find((o) => o.value === value)?.label ?? "",
                );
              }
              combobox.closeDropdown();
            }}
          >
            <Combobox.Target>
              <Button
                variant="light"
                id="applicationSelectorButton"
                color="blue"
                size="xs"
                justify="space-between"
                radius="xl"
                rightSection={<IconChevronUp size={16} />}
                onClick={() => {
                  combobox.toggleDropdown();
                }}
              >
                {options.find((o) => o.value == currentAppId)?.label}
              </Button>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>{AppOptions}</Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </Stack>
      </Flex>
    </AppShell.Navbar>
  );
}
